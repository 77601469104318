import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const TitleSpacer = styled.div`
  padding-bottom: 40px;
`;

export const InputsContainer = styled.div`
  padding-top: 5px;
`;

export const InputsAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TimerContainer = styled.div`
  display: inline-block;
  width: 50px;
`;
