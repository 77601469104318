import React, { FC, useEffect, useState } from "react";
import CautionIcon from "@icon-park/react/lib/icons/Caution";
import ArrowLeftIcon from "@icon-park/react/lib/icons/ArrowLeft";
import { format } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  ErrorContainer,
  FilledButton as Button,
  Text,
  theme,
} from "@ifgengineering/component-library";
import {
  HeaderContainer,
  InputsAndButtonContainer,
  InputsContainer,
  TimerContainer,
  TitleSpacer,
} from "./styled";
import {
  BackContainer,
  Cur8OtpInput,
  COUNTDOWN_TIME_IN_SECONDS,
} from "@ifgengineering/client-auth-components";

interface Cur8StyleOtpFormProps {
  resendOtp: () => void;
  loginURL?: string;
  handleCancel?: () => void;
  handleSubmit: ({
    otp,
  }: {
    otp: string;
    setStatus: (status?: any) => void;
  }) => Promise<void>;
  errorMessage?: string;
  lastCodeSent?: string | null;
  isLoading?: boolean;
  userEmail: string;
}

let startTime = COUNTDOWN_TIME_IN_SECONDS;
const getFormattedTime = () => {
  const durationAsDate = new Date(startTime * 1000);
  const formattedDuration = format(durationAsDate, "mm:ss");
  return formattedDuration;
};

const VerifyEmailForm: FC<Cur8StyleOtpFormProps> = ({
  handleCancel,
  handleSubmit,
  resendOtp,
  errorMessage,
  lastCodeSent,
  isLoading,
  userEmail,
}) => {
  const otpFormSchema = Yup.object().shape({
    otp: Yup.string()
      .length(6, "Must be exactly 6 digits")
      .required("Required"),
  });

  const [showResentOtpMessage, setShowResentOtpMessage] = useState(false);
  const [allowSendCode, setAllowSendCode] = useState(false);
  const [formattedTimer, setFormattedTimer] = useState("01:00");
  const [timer, setTimer] = useState<number>();

  const getTimeDifferenceSinceLastCode = () => {
    if (lastCodeSent) {
      const lastCodeSentDate = new Date(Number(lastCodeSent));
      const now = new Date();
      const diff = now.getTime() - lastCodeSentDate.getTime();
      const secondsPassed = Math.floor(diff / 1000);
      if (secondsPassed < COUNTDOWN_TIME_IN_SECONDS) {
        startTime = -secondsPassed;
      }
    }
  };

  const startTimer = () => {
    // The window is to help TS to understand it will return an number as id
    const timerId = window.setInterval(() => {
      setFormattedTimer(getFormattedTime());
      startTime = startTime - 1;
    }, 1000);

    setTimer(timerId);
  };

  const stopTimer = () => {
    // Same here
    window.clearInterval(timer);
  };

  const clearTimer = () => {
    startTime = COUNTDOWN_TIME_IN_SECONDS;
    setFormattedTimer(getFormattedTime());
  };

  useEffect(() => {
    clearTimer();
    startTimer();
    getTimeDifferenceSinceLastCode();

    return () => {
      clearTimer();
      stopTimer();
    };
  }, []);

  useEffect(() => {
    if (startTime < 0) {
      stopTimer();
      setFormattedTimer("00:00");
      setAllowSendCode(true);
    }
  }, [formattedTimer]);

  const handleBackButton = () => {
    stopTimer();
    if (handleCancel) {
      handleCancel();
    }
  };

  const handleSendVerificationCode = async () => {
    if (!allowSendCode) {
      return;
    }

    fadeResentOtpMessage();
    resendOtp?.();
  };

  const fadeResentOtpMessage = () => {
    setShowResentOtpMessage(true);
    stopTimer();
    setTimeout(() => {
      setShowResentOtpMessage(false);
      setAllowSendCode(false);
      clearTimer();
      startTimer();
    }, 3000);
  };

  return (
    <div data-testid="otp">
      {handleCancel && (
        <BackContainer onClick={handleBackButton}>
          <ArrowLeftIcon
            size={18}
            theme="outline"
            fill={theme.colors.BLUE600}
          />
          <Text type="S16" color="BLUE600">
            Back
          </Text>
        </BackContainer>
      )}
      <TitleSpacer />
      <HeaderContainer>
        <Text type="T32" color="SLATE800" fontFamily="archiasemibold">
          Verify Your Account
        </Text>
        <Text width="fit-content" type="P16" color="SLATE800">
          To ensure your account is secure and your email address is accurate,
          we require verification. Please enter the code sent to{" "}
          <Text type="H16" color="SLATE800" as="span">
            {userEmail}
          </Text>{" "}
          to complete the process.
        </Text>
      </HeaderContainer>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={otpFormSchema}
        onSubmit={async (values, { setStatus }) => {
          handleSubmit({ otp: values.otp, setStatus });
        }}
      >
        {({
          errors,
          touched,
          submitForm,
          isSubmitting,
          isValid,
          values,
          setFieldValue,
          resetForm,
        }) => {
          return (
            <>
              <InputsAndButtonContainer>
                <div>
                  <Text type="S16" color="SLATE800" fontFamily="Inter">
                    Enter Verification Code
                  </Text>
                  <InputsContainer>
                    <Cur8OtpInput
                      value={values.otp}
                      numInputs={6}
                      onChange={(otp: string) => {
                        setFieldValue("otp", otp);
                      }}
                      hasErrored={Boolean(errorMessage)}
                      isDisabled={isSubmitting}
                    />
                  </InputsContainer>
                  {errorMessage && (
                    <ErrorContainer>
                      <CautionIcon
                        size={14}
                        fill={theme.colors.ERROR800}
                        theme="filled"
                      />
                      <Text type="P14" color="ERROR800">
                        {errorMessage}
                      </Text>
                    </ErrorContainer>
                  )}
                </div>
                <Button
                  testId="verify-button"
                  type="submit"
                  height="56px"
                  text="Verify Account"
                  color="darkBlue"
                  disabled={
                    (errors.otp && touched.otp) ||
                    !isValid ||
                    !values.otp ||
                    isLoading
                  }
                  isLoading={isLoading}
                  onClick={submitForm}
                />
              </InputsAndButtonContainer>

              <div>
                {showResentOtpMessage ? (
                  <Text type="S16" color="SLATE800" fontFamily="Inter">
                    Verification Code sent!
                  </Text>
                ) : (
                  <>
                    <TimerContainer>
                      <Text type="S16" color="SLATE600" fontFamily="Inter">
                        {formattedTimer}
                      </Text>
                    </TimerContainer>
                    <Text
                      type="S16"
                      color={allowSendCode ? "BLUE600" : "SLATE450"}
                      fontFamily="Inter"
                      style={{ cursor: allowSendCode ? "pointer" : "" }}
                      onClick={() => {
                        handleSendVerificationCode();
                        resetForm();
                      }}
                    >
                      Resend Verification Code
                    </Text>
                  </>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default VerifyEmailForm;
